<template>
  <section id="dashboard-ecommerce" style="max-width: 100rem; margin: auto;">
    <b-row v-if="infos" class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-row>
          <b-col
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="UserIcon"
              :statistic="infos.active_users+ ' Users Buy Plans' "
              :statistic-title="infos.total_users+' Total Users'"
            />
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="DollarSignIcon"
              color="success"
              :statistic="'Active Plans: ' + infos.active_plans"
              :statistic-title="'Total Plans: '+ infos.total_plans"
            />
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="ActivityIcon"
              color="danger"
              :statistic="infos.active_keys+ ' Key actived'"
              :statistic-title="infos.total_keys +' Total Keys '"
            />
          </b-col>
          <b-col
            lg="3"
            sm="6"
          >
            <statistic-card-horizontal
              icon="ServerIcon"
              color="warning"
              :statistic="Number(infos.total_funds).toLocaleString('en-US', { style: 'currency', currency: 'USD' })  +' Funds'"
              :statistic-title="Number(infos.active_funds).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' Fund Used'"
            />
          </b-col>
        </b-row>
      </b-col>

    </b-row>

    <b-row class="match-height">
      <b-col md=12>
        <settings v-if="infos && infos.settings" :data="infos.settings"></settings>
      </b-col>
      <b-col md=12>
        <logs></logs>  
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- <b-col md=12>
        <settings></settings>
      </b-col>  -->
      <b-col md=12>
        <plan-report></plan-report>  
      </b-col> 
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
import Settings from './Settings.vue'
import AddFunds from './AddFundHistory.vue'
import Logs from './Logs.vue'
import PlanReport from './PlanReport.vue'
const DashboardManagersRepository = RepositoryFactory.get('dashboardmanager')

import data from './ultis'
import { t } from '@/@core/libs/i18n/utils'
export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
    'settings': Settings,
    'add-funds': AddFunds,
    'logs': Logs,
    'plan-report': PlanReport
  },
  data() {
    return {
      data: {},
      infos: {
        users: 0,
        plans: 0,
        invoice: 0,
        accounts: 0,
        availAccount: 0,
        settings: {
          price_per_port: 0,
          dollar_to_vnd: 0,
          prices: [
            {
              price: 0,
              min: 0,
              max: 0
            }
            
          ],
          geos_avail: []
        }
      },
      reports: null
    }
  },
  created() {
    DashboardManagersRepository.fetch().then(rs=>{
      this.infos = rs.data.data
    })
  },
  methods:{
    saveSettings(data){
      DashboardManagersRepository.saveSettings(data).then(rs=>{
        this.$bvToast.toast('Settings Saved', {
            title: 'Success',
            variant: 'success',
            solid: false,
          })
      })
    },
    formatBytes(bytes) {
      let specrator = ""
      if(bytes < 0)
      {
        specrator = "-"
        bytes = bytes*-1
      }
        
      
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return specrator + bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return specrator + (bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return specrator + (bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return specrator + (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return specrator + (bytes / teraBytes).toFixed(decimal) + " TB";
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
