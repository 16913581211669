<template>

  <b-card
    no-body
  >
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
      <b-card-header class="pb-50">
        <b-card-title >Plan Report</b-card-title>
        <!-- <div class="demo-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary">
                Create Port
              </b-button>
          </div> -->
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Filter By Username"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
                <v-select
                  multiple
                  :options="userList"
                  v-model="selected_user"
                  label="username"
                  value="username"
                  @select="filterByUser"
                />
                <b-button
                  @click="filterByUser"
                >
                  Find
                </b-button>
                <b-button
                  @click="clearFind"
                >
                  Clear
                </b-button>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-checkbox
            @change="onShowActive"
            >
              Show Active Plan
            </b-form-checkbox>
          </b-col>
          
          <b-col cols="4">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        

      </b-card-body>

      <b-table
        striped
        hover
        ref="table"
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="show_plans"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #cell(username)="data">
          {{ getUsername(data.item.uid) }}
        </template>
        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at) }}
        </template>
        <template #cell(actived_at)="data">
          {{ formatDateTime(data.item.actived_at) }}
        </template>

        <template #cell(expired_at)="data">
          {{ formatDateTime(data.item.expired_at) }}
        </template>


      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

    <!-- page length -->
    <b-form-group
      label="Per Page"
      label-cols="6"
      label-align="left"
      label-size="sm"
      label-for="sortBySelect"
      class="text-nowrap mb-md-0 mr-1"
    >
      <b-form-select
        id="perPageSelect"
        v-model="perPage"
        size="sm"
        inline
        :options="pageOptions"
      />
    </b-form-group>

    <!-- pagination -->
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    </b-card-body>
  </b-overlay>
</b-card>
</template>

<script>


import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BCol, BRow,
  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BOverlay, BTable, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BBadge
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import RepositoryFactory from '../../../api/RepositoryFactory'

const DashboardManagersRepository = RepositoryFactory.get('dashboardmanager')


export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BTable,
    vSelect,BFormSelect,BPagination, BInputGroup, BInputGroupAppend,BBadge,
    BCol, BRow, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BOverlay
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      plans: [],
      show_plans: [],
      show: true,
      fields: [
        { key: 'username', label: 'User', sortable: true },
        { key: 'oid', label: 'oid', sortable: true },
        { key: 'qty', label: 'stt', sortable: true },
        { key: 'created_at', label: 'date_created', sortable: true },
        { key: 'actived_at', label: 'date_actived', sortable: true },
        { key: 'expired_at', label: 'expired_at', sortable: true },
        { key: 'active', label: 'active', sortable: true },
      ],
      
      blur: '2px',
      perPage: 20,
      pageOptions: [2,5, 10, 20, 50, 100,200,500,1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      variant: 'dark',
      opacity: 0.85,
      userList: [],
      selected_user: []

    }
  },
  created(){
    DashboardManagersRepository.getPlanReport().then(rs=>{
      this.plans = rs.data.data
      this.show_plans = this.plans
      this.userList = rs.data.users
      this.totalRows = this.show_plans.length
      this.show_plans.sort((a,b)=>{
        return new Date(b.date_created) - new Date(a.date_created);
      })
      this.show = false
    })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.show_plans.length
  },
  methods: {
    onShowActive(e){
      if(e){
        this.show_plans = this.plans.filter(x=>x.active === true)
        this.totalRows = this.show_plans.length
      }else{
        this.show_plans = this.plans
        this.totalRows = this.show_plans.length
      }
    },
    filterByUser(e){

      if(this.selected_user.length > 0){
        const userL = this.selected_user.map(x=>x.username)
        this.show_plans = this.plans.filter(x=>userL.includes(x.username))
        this.totalRows = this.show_plans.length
      }else{
        this.show_plans = this.plans
        this.totalRows = this.show_plans.length
      }
    },
    getUsername(id){
      return this.userList.find(x=>x._id == id).username || "Not found"
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatDateTime(date){
      if(!date)
        return ""
      let d = new Date(date)

      return d.toLocaleString('vi-VN')
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('en-US', options).format(d)
    },
    clearFind(){
      this.show_plans = this.plans
      this.totalRows = this.show_plans.length
    },
    formatBytes(bytes) {
    let spector = ""
    if(bytes < 0){
      spector = "-"
      bytes = bytes * -1
    }
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return spector+bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return spector+(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return spector+(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return spector+(bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return spector+(bytes / teraBytes).toFixed(decimal) + " TB";
    },
    
  },  
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 5,
      wheelPropagation: false,
    }
    

    return {
      perfectScrollbarSettings,
    }
  },
  
}
</script>
<style>
  .noti {
    max-height: 400px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>