<template>

  <b-card
    no-body
    v-if="settings"
  >
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
      <b-card-header>
        <b-card-title>Settings</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md=6
            lg=6
            xs=12
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Exchange: 1$ = VND"
                    label-for="v-email"
                  >
                    <b-form-input
                      id="v-email"
                      v-model="settings.exchange"
                      type="text"
                      placeholder="Dollar to VND"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="saveSettings"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </b-col>
          <b-col
            md=6
            lg=6
            xs=12
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Price per 1 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_1"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 5 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_5"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 10 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_10"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 20 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_20"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 50 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_50"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 100 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_100"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 200 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_200"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Price per 500 key"
                    label-for="v-first-name"
                  >
                    <b-form-input
                      type="text"
                      id="v-first-name"
                      v-model="settings.price_500"
                      placeholder="Price"
                    />
                  </b-form-group>
                </b-col>

                <!-- email -->
              
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>  
  </b-card>

</template>

<script>

import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BCol, BRow,
  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BOverlay
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    vSelect,
    BCol, BRow, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BOverlay
  },
  props: {
    data: {
      type: Object,
      default:  ()=>{}
    },
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      // settings: {
      //   price_per_port: 0,
      //   dollar_to_vnd: 0,
      //   geos_avail: []
      // },
      settings: this.data,
      config: {
        type:{
          1: "Balance"
        },
        icon:{
          1: "DollarSignIcon"
        }
      },
      variant: 'dark',
      opacity: 0.85,
      blur: '2px',
      show: true

    }
  },
  created(){
    setTimeout(()=>{
      this.settings = Object.assign({},this.data)
      this.show = false
    },3000)
    this.show = false
  },
  methods: {
    saveSettings(){
      // if(this.selected.length > 0){
      //   this.selected.forEach(element => {
      //     this.settings.geos_avail.find(x=>x.name == element.name).block = true
      //   });
      // }else{
      //   this.settings.geos_avail.forEach(element => {
      //     element.block = false
      //   });
      // }
      


      this.$parent.saveSettings({
        exchange: this.settings.exchange,
        price: {
          price_1: this.settings.price_1,
          price_5: this.settings.price_5,
          price_10: this.settings.price_10,
          price_20: this.settings.price_20,
          price_50: this.settings.price_50,
          price_100: this.settings.price_100,
          price_200: this.settings.price_200,
          price_500: this.settings.price_500,
        }
      })
    },
    getamountVariant(amount){
      if(type == 1)
        return "light-danger"
    },
    formatDateTime(date){
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },  
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 5,
      wheelPropagation: false,
    }
    

    return {
      perfectScrollbarSettings,
    }
  }
}
</script>
<style>
  .noti {
    max-height: 400px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>