<template>

  <b-card
    no-body
  >
    <b-overlay
      :show="show"
      rounded="xl"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
    >
      <b-card-header class="pb-50">
        <b-card-title >Logs</b-card-title>
        <!-- <div class="demo-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary">
                Create Port
              </b-button>
          </div> -->
      </b-card-header>
      <b-card-body>
        <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-card-body>

      <b-table
        striped
        hover
        ref="table"
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="read_logs"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(user)="data">
          <b-badge v-if="data.item.user == 'System'" variant="warning">{{ data.item.user}}</b-badge>
          <b-badge v-else :variant="data.item.variant">{{ data.item.user}}</b-badge>
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at) }}
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      </b-card-body>
    </b-overlay>
  </b-card>

</template>

<script>


import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BCol, BRow,
  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BTable,BOverlay, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BBadge
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import RepositoryFactory from '../../../api/RepositoryFactory'

const DashboardManagersRepository = RepositoryFactory.get('dashboardmanager')


export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BTable,
    vSelect,BFormSelect,BPagination, BInputGroup, BInputGroupAppend,BBadge,
    BCol, BRow, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BOverlay
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      read_logs: [],

      fields: [
        { key: 'created_at', label: 'Time', sortable: true },
        { key: 'user', label: 'User', sortable: true },
        { key: 'log', label: 'Note', sortable: true },
      ],
      
      blur: '2px',
      perPage: 20,
      pageOptions: [2,5, 10, 20, 50, 100,200,500,1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],

      variant: 'dark',
      opacity: 0.85,
      show: true
    }
  },
  created(){

    DashboardManagersRepository.getLogs().then(rs=>{
      this.read_logs = rs.data.data
      this.totalRows = this.read_logs.length
      this.read_logs.sort((a,b)=>{
        return new Date(b.created_at) - new Date(a.created_at);
      })
      this.show = false
    })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.read_logs.length
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatDateTime(date){
      let d = new Date(date)
      return d.toLocaleString('vi-VN')
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('en-US', options).format(d)
    }
  },  
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 5,
      wheelPropagation: false,
    }
    

    return {
      perfectScrollbarSettings,
    }
  }
}
</script>
<style>
  .noti {
    max-height: 400px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>